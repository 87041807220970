<template>
    <div class='sellManage' style="padding-top: 20px;">
        <el-row>
            <div style="width: 100%">
                <el-form size="small" :inline="true" :model="searchForm" class="demo-form-inline">
                    <el-form-item label="名称：">
                        <el-input v-model="searchForm" clearable placeholder="请输入账号，姓名或邮箱">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSearch">查询</el-button>
                    </el-form-item>
                    <el-form-item style="float: right; margin-right: 0">
                        <el-button style="width: 100px" type="primary" @click="handleAddEditUser('add')">添加用户
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="width: 100%">
                <el-table :header-cell-style="{ background: '#F2F2F2' }" :data="accountManageDataList" border
                    align="center" style="width: 100%">
                    <el-table-column label="序号" width="90px" type="index" :index="indexMethod" align="center">
                    </el-table-column>
                    <el-table-column prop="username" label="账号" align="center"></el-table-column>
                    <el-table-column prop="realname" label="姓名" align="center"></el-table-column>
                    <el-table-column prop="email" label="邮箱" align="center"></el-table-column>
                    <el-table-column prop="role_name" label="分组名称" align="center"> </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" align="center"> </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template v-slot="scope">
                            <el-button @click="handleAddEditUser('edit', scope.row)" type="text" size="small">编辑
                            </el-button>
                            <el-button style="width: 40px;" @click="open(scope.row)" type="text" size="small">
                                {{ scope.row.is_lock
                                        === 0 ? '未锁定' : '锁 定'
                                }}</el-button>
                            <el-button @click="del(scope.row.id)" type="text" size="small">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right; margin-top: 25px" v-if="page.total != 0">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.page" :page-sizes="[10, 15, 30, 35]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                    </el-pagination>
                </div>
            </div>
        </el-row>
        <!-- 添加用户/编辑弹窗 -->
        <el-dialog v-model="dialogFormAddEditVisible" :title="type == 'edit' ? '编辑用户' : '添加用户'" width="600px" center
            :before-close="handleClose">
            <div class="sellManage">
                <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm"
                    :size="formSize" status-icon>
                    <el-form-item label="用户名" :prop="type == 'edit' ? '' : 'username'">
                        <el-input v-model="ruleForm.username" placeholder="请输入用户名"
                            :disabled="type == 'edit' ? true : false" />
                    </el-form-item>
                    <el-form-item label="密码" :prop="type == 'edit' ? '' : 'password'">
                        <el-input v-model="ruleForm.password" placeholder="请输入密码" />
                    </el-form-item>
                    <el-form-item label="确认密码" :prop="type == 'edit' ? '' : 'isPassword'">
                        <el-input v-model="ruleForm.isPassword" placeholder="请输入确认密码" />
                    </el-form-item>
                    <el-form-item label="姓名" :prop="type == 'edit' ? '' : 'name'">
                        <el-input v-model="ruleForm.name" placeholder="请输入姓名" />
                    </el-form-item>
                    <el-form-item label="分组" :prop="type == 'edit' ? '' : 'value'">
                        <el-cascader v-model="value" :options="selectList"
                            :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children' }" clearable
                            @change="handleChange"></el-cascader>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email">
                        <el-input v-model="ruleForm.email" placeholder="请输入邮箱地址" />
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button v-if="type == 'edit'" type="primary" @click="edit">保存</el-button>
                    <el-button v-else type="primary" @click="add">确定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { seller_user, seller_userAdd, seller_userEdit, seller_userChange_status, seller_userDelete, seller_role } from '../../common/js/sellManage'
export default {
    // 账号管理
    name: 'sellManage',
    // 组件参数 接收来自父组件的数据
    props: {},
    // import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {
            // 查询
            searchForm: '',
            // 账号管理列表
            accountManageDataList: [],
            // 分组列表
            selectList: [],
            // 选中的分组
            value: '',
            // 是否显示添加/编辑用户
            dialogFormAddEditVisible: false,
            // 添加/编辑类型
            type: '',
            // 提交数据表单
            ruleForm: {
                username: '',
                password: '',
                isPassword: '',
                name: '',
                region: [],
                email: '',
                id: '',
            },
            // 表格规则
            rules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ],
                isPassword: [
                    { required: true, message: '请再次输入密码', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                value: [
                    { required: true, message: '请选择分组', trigger: 'blur' }
                ],
                email: [
                    // { required: true, message: '请输入邮箱', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                ],
            },
            // 分页
            page: {
                page: 1,
                pageSize: 10,
                total: 0
            },
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {
        dialogFormAddEditVisible() {
            if (this.dialogFormAddEditVisible == false) {
                this.ruleForm = {
                    username: '',
                    password: '',
                    isPassword: '',
                    name: '',
                    region: [],
                    email: '',
                    id: '',
                }
                this.value = ''
            }
        }
    },
    // 方法集合
    methods: {
        // 初始化
        init() {
            let dt = {
                search: this.searchForm,
                page: this.page.page,
                page_size: this.page.pageSize
            }
            seller_user(dt).then(res => {
                let { code, result } = res
                if (code == 200) {
                    this.accountManageDataList = result.info
                    this.page.total = result.total
                }
            })
        },
        // 处理添加用户/编辑按钮
        handleAddEditUser(type, row) {
            this.type = type;
            if (type == 'edit') {
                this.ruleForm = {
                    username: row.username,
                    password: '',
                    isPassword: '',
                    name: row.realname,
                    region: this.value,
                    email: row.email,
                    id: row.id,
                }
                this.value = row.role_id
            }
            this.dialogFormAddEditVisible = true;
            seller_role().then(res => {
                let { code, result } = res
                if (code == 200) {
                    this.selectList = result
                }
            })
        },
        // 添加用户
        add() {
            let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
            if (!reg.test(this.ruleForm.email)) {
                this.$message.warning('请输入正确的邮箱')
                return;
            }
            let dt = {
                username: this.ruleForm.username,
                password: this.ruleForm.password,
                password1: this.ruleForm.isPassword,
                realname: this.ruleForm.name,
                email: this.ruleForm.email,
                role_id: this.value,
            }
            seller_userAdd(dt).then(res => {
                let { code, msg } = res
                if (code == 200) {
                    this.$message.success(msg)
                    this.init()
                    this.dialogFormAddEditVisible = false
                }
            })
        },
        // 编辑用户
        edit() {
            let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
            if (!reg.test(this.ruleForm.email)) {
                this.$message.warning('请输入正确的邮箱')
                return;
            }
            let dt = {
                id: this.ruleForm.id,
                username: this.ruleForm.username,
                password: this.ruleForm.password,
                password1: this.ruleForm.isPassword,
                realname: this.ruleForm.name,
                email: this.ruleForm.email,
                role_id: this.value,
            }
            seller_userEdit(dt).then(res => {
                let { code, msg } = res
                if (code == 200) {
                    this.$message.success(msg)
                    this.init()
                    this.dialogFormAddEditVisible = false
                }
            })
        },
        // 锁定解锁
        open(row) {
            let dt = {
                id: row.id,
                status: row.is_lock == 1 ? 0 : 1
            }
            this.$confirm(`是否${row.is_lock == 0 ? '锁定' : '解锁'}当前用户账号？`, row.is_lock == 0 ? '锁定' : '解锁', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true,
                type: 'warning'
            })
                .then(() => {
                    seller_userChange_status(dt).then(res => {
                        let { code, msg } = res
                        if (code == 200) {
                            this.$message.success(msg)
                            this.init()
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 删除
        del(id) {
            this.$confirm(`是否删除当前用户账号？`, '删除用户', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true,
                type: 'warning'
            })
                .then(() => {
                    seller_userDelete({ id }).then(res => {
                        let { code, msg } = res
                        if (code == 200) {
                            this.$message.success(msg)
                            this.init()
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        handleChange(value) {
            if (value) {
                this.value = value[value.length - 1]
            }
        },
        // 处理查询按钮
        handleSearch() {
            this.init();
        },
        // 下标序号
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },
        // 分页处理
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.init();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.init();
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.init()
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() { },
    // 这里存放数据
    beforeCreate() { },
    // 生命周期 - 挂载之前 
    beforeMount() { },
    // 生命周期 - 更新之前 
    beforeUpdate() { },
    // 生命周期 - 更新之后 
    updated() { },
    // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { }
}
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
::v-deep.demo-ruleForm {
    .el-input__inner {
        width: 400px;
    }
}

// ::v-deep.el-cascader__dropdown {
//     border: 1px solid #000;
//     width: 400px;
//     overflow-x: scroll;
// }
</style>
<style>
.el-cascader-panel {
    max-width: 800px !important;
    overflow-x: scroll;
}
</style>
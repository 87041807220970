import $http from '../../utils/http';

/**
 * 用户列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function seller_user(data) {
    return $http.get('/seller/user/index', data, 'loadingDiv');
}

/**
 * 添加账号
 * @param data
 * @returns {Promise<unknown>}
 */
export function seller_userAdd(data) {
    return $http.post('/seller/user/add', data, 'loadingDiv')
}

/**
 * 修改账号
 * @param data
 * @returns {Promise<unknown>}
 */
export function seller_userEdit(data) {
    return $http.post('/seller/user/edit', data, 'loadingDiv')
}

/**
 * 账号锁定/解锁
 * @param data
 * @returns {Promise<unknown>}
 */
export function seller_userChange_status(data) {
    return $http.post('/seller/user/change_status', data, 'loadingDiv')
}

/**
 * 删除账号
 * @param data
 * @returns {Promise<unknown>}
 */
export function seller_userDelete(data) {
    return $http.post('/seller/user/delete', data, 'loadingDiv')
}

/**
 * 分组列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function seller_role(data) {
    return $http.get('/seller/role/index', data, 'loadingDiv');
}

/**
 * 添加分组
 * @param data
 * @returns {Promise<unknown>}
 */
export function seller_roleAdd(data) {
    return $http.post('/seller/role/add', data, 'loadingDiv')
}

// /**
//  * 修改分组状态
//  * @param data
//  * @returns {Promise<unknown>}
//  */
// export function seller_roleChange_status(data) {
//     return $http.post('/seller/role/change_status', data, 'loadingDiv')
// }

/**
 * 删除分组
 * @param data
 * @returns {Promise<unknown>}
 */
export function seller_roleDelete(data) {
    return $http.post('/seller/role/delete', data, 'loadingDiv')
}

/**
 * 修改分组
 * @param data
 * @returns {Promise<unknown>}
 */
export function seller_roleEdit(data) {
    return $http.post('/seller/role/edit', data, 'loadingDiv')
}
